require(['jquery'], function ($) {

  if ($(".xm-grid-product").length > 0 && $('button.btn-cart').length > 0) {
    $('body').append("<div class='add-cart-fixed' style='display: none;'><span>" + $('button.btn-cart:first').text() + "</span></div>");
    $('body').on('click', '.add-cart-fixed', function () {
      $([document.documentElement, document.body]).animate({
        scrollTop: $(".product-shop").offset().top
      }, 1000);

      $('.product-options').addClass('border-out');

    });
  }

  var isScrolledIntoView = function (elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  };

  /* floating header */
  var halfScreenSize = ($(document).width() * 0.5);
  $(window).scroll(function () {
    $this = $(this);
    var topSize = 185;
    if ($this.width() <= 960) {
      topSize = 36;
    }
    if ($this.scrollTop() >= topSize) {
      if (!$('#header').hasClass('floating')) {
        $('body').css('padding-top', $('.header-primary-container').height());
        $('#header').addClass('floating');
        $('#header').slideDown('fast');
      }
    }
    if ($this.scrollTop() < topSize) {
      if ($('#header').hasClass('floating')) {
        $('body').attr('style', '');
        $('#header').removeClass('floating');
        $('#header').attr('style', '');
      }
    }

    if ($('button.btn-cart').length > 0) {
      var isVisibleOnScreen = isScrolledIntoView('button.btn-cart');
      if (isVisibleOnScreen) {
        $('.add-cart-fixed').hide();
        $('.add-cart-fixed').width('50%');
      } else {
        if ($('.add-cart-fixed').width() === halfScreenSize) {
          $('.add-cart-fixed').show().animate({ width: '100%' });
        }
      }
    }
  });

  if ($("#mini-cart").length > 0) {
    $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
  }

  $(".form-search .button.button-search").html("<i class='fa fa-search'></i>");

  if ($(".variant-option").length > 0) {
    if ($(".variant-option .swatches-con .swatch img").length > 0) {
      $(".variant-option .swatches-con").css('display', 'block');
    }
  }

  if ($(".page-title.category-title").length > 0) {
    $(".display-mode-grid").prepend("<div id='pageTitle'>" + $(".page-title.category-title").html() + "</div>");
  }

  if ($(".sidebar.in-sidebar").length > 0) {
    var text = $(".sidebar.in-sidebar:first .block-title span").text();
    $(".sidebar.in-sidebar:first .block-title span").text(text.replace('Categories:', ''));

    if ($(".mh-filters-btn").length > 0 && $("#pageTitle").length > 0) {
      $("#pageTitle").append($(".mh-filters-btn").parent().html());
      $(".mh-filters-btn").addClass("hide-above-960");
    }
  }

  if ($(".module-header-multi").length > 0) {

    if ($("#mini-cart").length > 0 && $(".nav-cart").length === 0) {
      $('.module-header-multi').prepend("<div class='show-below-960 item item-right nav-cart'>" + $("#mini-cart").html() + "</div>");
    }
    if ($(".nav-search").length === 0) {
      $('.module-header-multi').prepend("<div class='show-below-960 item item-right nav-search'>" + $(".search-wrapper").html() + "</div>");
    }
    if ($(".nav-logo").length === 0) {
      $('.module-header-multi').prepend("<div class='show-below-960 item item-right nav-logo'>" + $(".logo-wrapper").html() + "</div>");
    }
  }



  $(".nav-search .form-search .button.button-search").on("click", function (e) {
    if (!$(".nav-search").hasClass("fly-out")) {
      e.preventDefault();
      $(".nav-search").addClass("fly-out");
      $(".nav-search .form-search .input-text").focus();
      $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
    }
  });

  $(window).on('resize', onResize);

  $(document).ready(function () {
    onResize();
  });

  var onResize = function () {
    if ($(window).width() < 960) {
      $(".user-link-wrapper ul.links").find("li").addClass("mm-listitem");
      $(".user-link-wrapper ul.links").children().find("a").addClass("mm-btn mm-btn_next mm-listitem__btn mm-listitem__text");
      $(".user-link-wrapper ul.links").children().appendTo("#xmMmenu .mm-listview");
    }
  };

  $(document).on('click', '.nav-search .button-close', function (e) {
    e.preventDefault();
    $(".nav-search").removeClass("fly-out");
  });

});